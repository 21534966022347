<template>
  <div class="page" id="print">
    <el-form
        ref="search"
        :inline="true"
        size="small"
    >
      <el-form-item label="缴费方式:" class="searchItem" v-if="pageParams.payType">
        {{pageParams.payType==1?'微信':'现金'}}
      </el-form-item>
      <el-form-item label="时间段:" class="searchItem" v-if="pageParams.startTime">
        {{pageParams.startTime}}--{{pageParams.endTime}}
      </el-form-item>
      <el-form-item label="操作人:" class="searchItem" v-if="pageParams.operBy">
        {{pageParams.operBy}}
      </el-form-item>
    </el-form>
    <table width="100%" border="1" cellpadding="0" cellspacing="0" min-height="600px">
      <tr style="height: 40px;text-align: center">
        <td rowspan="2" style="border-right: none;border-top: none">收款人姓名</td>
        <td rowspan="2" style="border-right: none;border-top: none">总数量</td>
        <td rowspan="2" style="border-right: none;border-top: none">总金额</td>
        <td colspan="2" v-for="(item,index) in tableTitle" :index="index" style="border-right: none;border-top: none">{{ item }}</td>
      </tr>
      <tr style="height: 40px;text-align: center">
        <div v-for="(item,index) in tableTitle" :index="index">
          <td style="border-right: none;border-top: none">数量</td>
          <td style="border-right: none;border-top: none">金额</td>
        </div>
      </tr>
      <tr v-for="(item,index) in tableData" :key="index" style="height: 40px;text-align: center">
          <td style="border-right: none;border-top: none">{{item.operBy}}</td>
          <td style="border-right: none;border-top: none">{{item.totalNum}}</td>
          <td style="border-right: none;border-top: none">{{item.totalMoney}}</td>
          <div v-for="(one,j) in item.detailList " :key="j">
            <td style="border-right: none;border-top: none">{{one.materialTotalNum}}</td>
            <td style="border-right: none;border-top: none">{{one.materialTotalMoney}}</td>
          </div>
      </tr>
    </table>
  </div>
</template>

<script>
import {
  selectMaterialOrderRecordHead,
  selectMaterialPaymentRecord,
  selectMaterialPaymentTotal
} from "../../RequestPort/kfOrder";

export default {
  name: "print",
  data(){
    return{
      pageParams:{},
      tableData:[],
      tableTitle:[],
      totalDetail:{}
    }
  },
  mounted() {
    this.pageParams = this.$route.query
    selectMaterialOrderRecordHead().then(res=>{
      this.tableTitle = res.data
      this.loadList(this.pageParams);
    })
  },
  methods:{
    loadList(obj){
      selectMaterialPaymentRecord(obj).then(res=>{
        this.tableData = res.data.records
        selectMaterialPaymentTotal(obj).then(reslult =>{
          this.tableData.push(reslult.data[0])
          this.$nextTick((e)=>{
            this.$print('#print', {
              afterprint: () => {
                // this.$router.go(-1)
              },
              cancel: () => {
                this.$router.go(-1)
              }
            },63)
          })
        })
      })
    },
  }
}
</script>

<style scoped lang="less">
.searchItem{
  margin-right: 20px;
}
.page {
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #fff;
  padding: 10px;
  box-sizing: border-box;
}
</style>